export const rules = {
  emailRules: [
    (v) => !!v || "Esse campo é obrigatório",
    (v) => /.+@.+\..+/.test(v) || "Ensira um e-mail valido",
  ],
  passwordRules: [(value) => !!value || "Esse campo é obrigatório"],
  nameRules: [(v) => !!v || "Esse campo é obrigatório"],
  genericRules: [(v) => !!v || "Esse campo é obrigatório"],
  selectRules: [(v) => !!v || "Esse campo é obrigatório"],
  urlRules: [
    (v) => !!v || "Esse campo é obrigatório",
    (v) =>
      /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?|magnet:\?xt=urn:btih:/.test(
        v
      ) || "Insira uma link válido",
  ],
  imgRules: [
    (value) =>
      !value || value.size < 2000000 || "Avatar size should be less than 2 MB!",
  ],
  multiSelectRules: [(v) => v.length > 0 || "Esse campo é obrigatório"],
  maxCharacters: [
    (v) => !!v || "Esse campo é obrigatório",
    (v) =>
      (v || "").length <= 255 ||
      `É permitido um máximo de 255 caracteres`,
  ],
};
