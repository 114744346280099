import { apiInstance } from "@/utils/api-instance";
import { ENDPOINTS } from "@/utils/constants";

export const listLine = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.LINE_LIST, payload);
    return data
}

export const searchLine = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.LINE_SEARCH, payload);
    return data
}

export const storeLine = async (payload) => {
    const { data } = await apiInstance.post(ENDPOINTS.LINE_STORE, payload);
    return data
}

export const showLine = async (id) => {
    const { data } = await apiInstance.get(ENDPOINTS.LINE_SHOW + `/${id}`);
    return data
}

export const editLine = async (id, payload) => {
    const { data } = await apiInstance.put(ENDPOINTS.LINE_EDIT + `/${id}`, payload);
    return data
}

export const deleteLine = async (id) => {
    const { data } = await apiInstance.delete(ENDPOINTS.LINE_DELETE + `/${id}`);
    return data
}